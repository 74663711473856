<template>
   <v-container 
      style="max-width: 2000px;padding-top:66px;padding-bottom:46px;background-color:white;height:100vh;width:100vw;overflow-y:auto;overflow-x:hidden;"
      class="bgImage"
      :style="{
         'background-image': backgroundImg?backgroundImg:'none',
         'background-color': backgroundColor?backgroundColor:'none'
         }"
      >
      <v-row class="pa-1"
         :class="{
            'pa-1': $vuetify.breakpoint.smAndDown,
            'px-4 py-1': $vuetify.breakpoint.mdAndUp,
         }"
         >
         <template v-for="(widget, wIndex) in modal.widgets">
            <component 
               :is="widget.widget_type"
               :modal="widget"
               :key="wIndex"
               @requestShowSetter="showSetter">
            </component>
         </template>
      </v-row>
   </v-container>
</template>

<script>
import listener from "@/mixins/listener.js"
import setter from "@/mixins/setter.js"
export default {
   props: ['modal', 'customStyle'],
   mixins: [listener, setter],
   data () {
      return {
         widgets: [],
         backgroundImg: null,
         backgroundColor: null,
         blurBackground: true,
         FETCH_ON_START: true,
      }
   },
   created () {
      if (this.modal && this.modal.widgets) {
         this.widgets = this.modal.widgets
      }

      if (this.customStyle) {
         if(this.customStyle.backgroundImg != undefined) this.backgroundImg = this.customStyle.backgroundImg
         if(this.customStyle.backgroundColor != undefined) this.backgroundColor = this.customStyle.backgroundColor
         if(this.customStyle.transparentColor != undefined) this.transparentColor = this.customStyle.transparentColor
         if(this.customStyle.blurBackground != undefined) this.blurBackground = this.customStyle.blurBackground
      }
   }
}
</script>

<style>
   .bgImage {
      z-index:1;
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background-size:cover;
   }
</style>